import React, { useCallback } from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import theme from "../theme";

import { ChakraProvider } from "@chakra-ui/react";

import {
  Flex,
  Image,
  Text,
  Stack,
  Box,
  Heading,
  SimpleGrid,
  VStack,
  Button,
} from "@chakra-ui/react";

import { GiSpray } from "react-icons/gi";
import { FiGrid } from "react-icons/fi";
import { FaEuroSign } from "react-icons/fa";
import { BiSort } from "react-icons/bi";

import products from "../products.js";

import accessories from "../images/accessories-min.jpg";
import { graphql, Link } from "gatsby";

import GlobalStyle from "../globalStyles";

const Doplnky = ({ location }) => {
  const formatter = new Intl.NumberFormat("sk-SK", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const onlyOils = products.filter((product) => product.type === "doplnok");

  const subCategory = location.search.split("=")?.[1] || "";
  const setSubCategory = useCallback((newSubCategory) => {
    window.location.search = `?subCategory=${newSubCategory}`;
  }, []);

  const displayAllOils = onlyOils.filter((product) =>
    subCategory === "" ? true : product.sub_category === subCategory
  );

  const numberOfProducts = displayAllOils.length;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DMS-VID - Svet pre vaše auto :: Autodoplnky</title>
      </Helmet>

      <GlobalStyle />

      <ChakraProvider theme={theme}>
        <Navbar
          home="whiteAlpha.600"
          oils="whiteAlpha.600"
          accessories="red.500"
          contact="whiteAlpha.600"
        />

        <Flex
          w={"full"}
          backgroundImage={accessories}
          backgroundSize={"cover"}
          backgroundPosition={"0px -20rem"}
          backgroundRepeat="no-repeat"
          backgroundAttachment="fixed"
        >
          <VStack
            w={"full"}
            justify={"center"}
            bgGradient={"linear(to-b, blackAlpha.700, blackAlpha.600)"}
            py={{ base: 12, md: 16, lg: 20, xl: 24 }}
          >
            <Stack maxW={"8xl"} align={"center"}>
              <Text
                color={"white"}
                fontWeight={800}
                lineHeight={1.2}
                fontSize={{ base: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
                align="center"
                mt={20}
              >
                <Flex alignItems="center">
                  <Box mr={2} color="red.500">
                    <GiSpray />
                  </Box>{" "}
                  AUTODOPLNKY
                </Flex>
              </Text>
            </Stack>
          </VStack>
        </Flex>

        <Box
          px={{
            base: "24px",
            md: "48px",
            lg: "96px",
            "2xl": "256px",
          }}
          bg="white"
          transition="all 250ms"
          py={{ base: 12, xl: 24 }}
          borderBottom="1px"
          borderColor="blackAlpha.300"
        >
          <Flex
            align="center"
            width="100%"
            borderBottom="1px"
            borderColor="blackAlpha.300"
            pb={4}
            flexWrap="wrap"
            justify="space-between"
            color="black.900"
            alignItems="center"
          >
            <Flex justify="start" align="center" alignItems="center" py={6}>
              <Box mr={2}>
                <FiGrid />
              </Box>
              <Text fontWeight="600">{numberOfProducts} výsledkov</Text>
            </Flex>

            <Flex justify="start" align="flex-end" flexWrap="wrap">
              <Link to="?">
                <Button
                  variant="ghost"
                  color={subCategory === "" ? "red.500" : "black.800"}
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Všetko
                </Button>
              </Link>

              <Link to="?subCategory=elektronika">
                <Button
                  variant="ghost"
                  color={
                    subCategory === "elektronika" ? "red.500" : "black.800"
                  }
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Elektronika
                </Button>
              </Link>

              <Link to="?subCategory=interier">
                <Button
                  variant="ghost"
                  color={subCategory === "interier" ? "red.500" : "black.800"}
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Interiér
                </Button>
              </Link>

              <Link to="?subCategory=exterier">
                <Button
                  variant="ghost"
                  color={subCategory === "exterier" ? "red.500" : "black.800"}
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Exteriér
                </Button>
              </Link>

              <Link to="?subCategory=cistenie">
                <Button
                  variant="ghost"
                  color={subCategory === "cistenie" ? "red.500" : "black.800"}
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Čistenie a starostlivosť
                </Button>
              </Link>

              <Link to="?subCategory=bezpecnost">
                <Button
                  variant="ghost"
                  color={subCategory === "bezpecnost" ? "red.500" : "black.800"}
                  _focus={{ outlineColor: "red.500" }}
                  _hover={{ backgroundColor: "red.50" }}
                  _active={{ backgroundColor: "red.50" }}
                >
                  Bezpečnosť
                </Button>
              </Link>
              {/*<Button variant="ghost" leftIcon={<BiSort />} ml={5}>
                <FaEuroSign />
        </Button>*/}
            </Flex>
          </Flex>

          <SimpleGrid
            columns={{ base: "1", sm: "2", lg: "4" }}
            spacing={4}
            mt={8}
          >
            {displayAllOils.map((product) => (
              <Flex
                p={6}
                py={8}
                w="full"
                border="1px"
                borderColor="blackAlpha.200"
                boxShadow={"lg"}
                _hover={{ boxShadow: "dark-lg", cursor: "pointer" }}
                rounded={"lg"}
                flexWrap="wrap"
                justify="center"
                transition="all 250ms"
                key={product.id}
              >
                <Image
                  rounded={"lg"}
                  height={60}
                  width="100%"
                  objectFit="contain"
                  src={product.image}
                />
                <Box>
                  <Stack pt={7} align={"center"}>
                    <Text
                      color={"black.500"}
                      fontSize={"sm"}
                      textTransform={"uppercase"}
                      align="center"
                    >
                      {product.brand}
                    </Text>
                    <Heading
                      fontSize={"2xl"}
                      fontFamily={"body"}
                      fontWeight={500}
                      align="center"
                    >
                      {product.name}
                    </Heading>
                    <Stack direction={"row"} align={"center"}>
                      <Text fontWeight={800} fontSize={"xl"}>
                        {product.price}€
                      </Text>
                      <Text textDecoration={"line-through"} color={"gray.600"}>
                        {formatter.format(product.price + product.price / 3)}€
                      </Text>
                    </Stack>
                  </Stack>
                </Box>
              </Flex>
            ))}
          </SimpleGrid>
        </Box>

        <Footer />
      </ChakraProvider>
    </>
  );
};

export default Doplnky;

export const queryDoplnky = graphql`
  query PageQueryDoplnky {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
